<template>
	<div class="content-wrap" ref="container">
		<div class="side-menu">
			<a-menu :selectedKeys="activeMenu" mode="inline">
				<a-menu-item :key="item.name" v-for="item in menuList">
					<router-link :to="{name: item.name}">{{ item.title }}</router-link>
				</a-menu-item>
			</a-menu>
		</div>
		<div class="main" id="main">
			<!-- <breadcrumb /> -->
			<router-view class="bg-white" />
			<div class="performance-modal" v-if="performanceVisible">
				<div class="performance-dialog">
					<div class="performance-dialog-img">
						<div>
							<p>高级版</p>
							<p>专属服务功能</p>
						</div>
						<p>升级版本请联系与您对接的销售</p>
					</div>
					<div class="performance-dialog-text">
						<p class="performance-dialog-text-title">绩效设置</p>
						<p class="performance-dialog-text-subtitle">销售预订的包间销售额与提成实时查看</p>
						<div class="performance-dialog-text-li">
							<span />
							<p>顾客结账前台输入销售额清台，自动核算提成，数据实时更新，无需手动统计。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from '@/layouts/Breadcrumb'
export default {
	name: 'DedicatedServiceManager',
	components: {Breadcrumb},
	data() {
		return {
			menuList: [
				{title: '储备金', name: 'reserveFund'},
				{title: '门店定金', name: 'shopDownPayment'},
				{title: '定金设置', name: 'setDownPayment'}
			],
			performanceVisible: false
		}
	},
	mounted() {},
	watch: {},
	methods: {},
	computed: {
		activeMenu() {
			return [this.$route.name]
		}
	}
}
</script>

<style scoped lang="less">
.ant-menu-inline {
	border-right: 0;
}
.content-wrap {
	background-color: #f0f2f5;
	width: 100%;
	min-height: calc(100vh - 66px);
}

.side-menu {
	position: fixed;
	left: 0;
	top: 66px;
	box-sizing: border-box;
	padding-top: 25px;
	width: 208px;
	height: calc(100vh - 66px);
	background-color: #fff;
}

.main {
	min-height: calc(100vh - 66px);
	position: relative;
	margin-left: 208px;
	overflow: hidden;
	padding: 20px;
}

.performance-modal {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 10;
	user-select: none;
}

.performance-dialog {
	display: flex;
	margin: 127px auto 0;
	width: 680px;
	height: 320px;
	background: #ffffff;
	box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
	border-radius: 6px;
}

.performance-dialog-img {
	position: relative;
	width: 50%;
	height: 100%;
	background-image: url(~@/assets/performance.png);
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;

	> div {
		margin-top: 60px;
		font-weight: 600;
		font-size: 32px;
		color: #ffffff;
		letter-spacing: 1.07px;
		text-align: center;
		line-height: 50px;
	}

	> p {
		font-size: 16px;
		color: #ffffff;
		margin-top: 45px;
		text-align: center;
	}
}

.performance-dialog-text {
	width: 50%;
	padding: 0 30px;
	margin-top: 106px;
}

.performance-dialog-text-title {
	font-weight: 600;
	font-size: 20px;
	color: #333333;
	line-height: 22px;
}

.performance-dialog-text-subtitle {
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 14px;
	color: #333333;
	line-height: 16px;
}

.performance-dialog-text-li {
	display: flex;

	span {
		display: block;
		margin-top: 8px;
		min-width: 4px;
		height: 4px;
		border-radius: 50%;
		background: #e63e30;
		margin-right: 8px;
	}

	font-weight: 400;
	font-size: 12px;
	color: #666660;
	line-height: 20px;
}

.service-wrap {
	display: flex;
	align-items: center;
}

.service-item {
	width: 320px;
	height: 478px;
	margin-right: 30px;
	background: #ffffff;
	border: 1px solid #e6e6e6;
	border-radius: 6px;
	padding: 30px 10px;
	text-align: center;

	&:last-child {
		margin-right: 0;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.service-item-title {
	font-weight: 500;
	font-size: 16px;
	color: #333333;
	margin-top: 43px;
	margin-bottom: 12px;
}

.service-item-text {
	font-weight: 400;
	font-size: 13px;
	color: #999999;
	line-height: 20px;
}

::v-deep .service-modal-warp {
	top: 66px;
}
</style>
